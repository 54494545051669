<template>
  <v-stepper v-model="model" flat class="transparent">
    <v-stepper-header style="box-shadow: none">
      <template v-for="({ complete, editable, step, label, click }, index) in steps">
        <v-divider v-if="index" :key="`${step}__divider`" class="mx-2" />

        <v-stepper-step
          :key="step"
          :step="step"
          :editable="!!editable"
          :complete="!!complete || model > step"
          :color="getStepColor(step, complete || step < model)"
          class="px-0"
          @click="click && click(model)"
        >
          <span :class="`${getStepColor(step, complete || step < model)}--text`">
            {{ label }}
          </span>
        </v-stepper-step>
      </template>
    </v-stepper-header>
    <v-row justify="space-between" class="px-6 py-4" style="flex-wrap: nowrap; font-size: 0.95em">
      <v-col v-if="steps[value - 1] !== undefined" cols="5" class="d-flex d-md-none justify-center mt-n2 primary--text">
        {{ steps[value - 1].mobileLabel }}
      </v-col>
      <v-col v-if="value <= steps.length - 1" cols="2" class="d-flex d-md-none justify-center mt-n2 primary--text">
        <v-icon color="grey">
          mdi-arrow-right
        </v-icon>
      </v-col>
      <v-col
        v-if="value <= steps.length - 1"
        cols="5"
        class="d-flex d-md-none justify-center mt-n2 grey--text text-right"
      >
        {{ steps[value].mobileLabel }}
      </v-col>
    </v-row>
    <slot />
  </v-stepper>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

export interface Step {
  complete?: boolean;
  editable?: boolean;
  step: number;
  label?: string;
  mobileLabel?: string;
  click?: (model: number) => unknown;
  onSubmit?: () => boolean;
  errors?: Record<string, boolean>;
}

export default Vue.extend({
  name: "TStepper",

  props: {
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
    value: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(step: number) {
        this.$emit("input", step);
      },
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },

  methods: {
    getStepColor(step: number, complete: boolean): string | undefined {
      if (step === this.model) return "primary";

      if (complete) return "tertiary";

      return;
    },
  },
});
</script>
