<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    @keypress.enter="submit"
  >
    <p class="text-justify mb-5">
      Um código de verificação foi enviado para o seu e-mail. Insira o código no campo abaixo para avançar. Este código
      é válido por 15 minutos!
    </p>

    <!-- <v-otp-input length="6"></v-otp-input> -->
    <v-otp-input
      v-model="code"
      required
      length="6"
      :rules="rules.code"
    />

    <v-row>
      <v-col>
        <t-btn
          block
          color="grey"
          class="heading--text"
          large
          :disabled="loading"
          @click="$emit('return')"
        >
          Voltar
        </t-btn>
      </v-col>
      <v-col>
        <t-btn
          block
          color="success"
          type="submit"
          class="heading--text"
          large
          :loading="loading"
          :disabled="!valid"
        >
          Avançar
        </t-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import axios from "axios";
import getPasswordRecoveryToken from "@/api/requests/getPasswordRecoveryToken";

export default Vue.extend({
  name: "FormValidateRecoverCode",

  components: {
    TBtn,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },

  data: () => ({
    valid: true,
    loading: false,
    code: "",
    rules: {
      code: [ (v: string) => !!v || "O código é obrigatório" ],
    },
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  methods: {
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
    async submit() {
      try {
        if (this.form.validate()) {
          this.loading = true;

          const token = await getPasswordRecoveryToken(this.user.document, this.code);
          this.$emit("submit", token);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          this.$snackbar("Código não encontrado", "error");
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
