import Vue from "vue";
import VueRouter from "vue-router";
import MiddlewareProvider from "./middlewares";
import store from "@/store";
import { token } from "./middlewares/token";
import { company } from "./middlewares/company";
import { routes } from "./routes";
import { analytics } from "./middlewares/analytics";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    return {
      selector: to.hash != "" ? to.hash : "#scroll-anchor",
      behavior: "smooth",
    };
  },
});

MiddlewareProvider.register(router, store, [company, token, analytics]);

export default router;
