<template>
  <v-select
    v-bind="{ ...$props, ...$attrs }"
    outlined
    :rules="getRules"
    :background-color="backgroundColor"
    v-on="$listeners"
  >
    <template v-if="withIcons" #item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" dense v-on="on">
        <v-list-item-action>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item[itemText] }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="withIcons" #selection="{ item, on, attrs }">
      <v-row v-bind="attrs" v-on="on">
        <v-col cols="1">
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-col>
        <v-col class="d-flex align-center ps-8">
          {{ item[itemText] }}
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TSelect",

  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredErrorMessage: {
      type: String,
      default: "Este campo é obrigatório",
    },
    rules: {
      type: Array as PropType<((v: string | number) => boolean | string)[] | []>,
      default: () => [],
    },
    withIcons: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
  },

  computed: {
    getRules(): ((v: string | number) => boolean | string)[] | [] {
      const required = this.required ? [ (v: string | number) => !!v || this.requiredErrorMessage ] : [];

      return [...this.rules, ...required];
    },
  },
});
</script>
