export enum TrackableEvent {
  AppDownload,
  AppView,
  PageView,
  Login,
  Register,
  AddToCart,
  RemoveFromCart,
  AddPaymentInfo,
  AbandonCart,
  Purchase,
};
