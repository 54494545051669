// This is needed for an override on window.global that used to be declared by webpack
// and it's not declared by Vite. Some libs (card.js in this project so far) still use this field so
// we need to declared it ourselves

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Window {
  global: unknown;
}

window.global ||= window;
