import getUserData from "@/api/requests/getUserData";
import { MiddlewareContext } from ".";

/**
 * Searches for a base64 encoded JWT token on the query string and logs the
 * user by this token if found.
 *
 * @param context `MiddlewareContext`
 * @returns `Promise<void>`
 */
export async function token(context: MiddlewareContext) {
  if (!context.to.query.token) return context.next();

  if (typeof context.to.query.token !== "string") return context.next();

  // For admins. Authenticates the user if a valid token is provided
  const token = JSON.parse(atob(context.to.query.token));

  context.store.commit("user/setToken", {
    token_type: "password",
    access_token: token.accessToken,
    refresh_token: "",
    expires_in: 60 * 60,
  });

  const user = await getUserData();

  context.store.commit("user/setUser", user.data);

  return context.next("/user/data");
}
