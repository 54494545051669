<template>
  <t-text-input
    v-model="cep"
    v-mask="'#####-###'"
    label="CEP"
    required
    hide-details="auto"
    @input="cep.length === 9 && fetchCEP()"
  />
</template>

<script lang="ts">
import Vue from "vue";
import axios from "axios";
import TTextInput from "@/components/core/TTextInput.vue";

import Address from "@/types/Address";

export interface ViaCepResponse {
  localidade: string;
  logradouro: string;
  uf: string;
  bairro: string;
  cep: string;
  gia: string;
  ibge: string;
  complemento: string;
  siafi: string;
  ddd: string;
}

export default Vue.extend({
  name: "TCepInput",

  components: {
    TTextInput,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    cep: {
      get(): string {
        return this.value;
      },
      set(cep: string) {
        this.$emit("input", cep);
      },
    },
  },

  methods: {
    fetchCEP() {
      axios.get(`https://viacep.com.br/ws/${this.cep}/json/`).then((res) => {
        const { bairro, logradouro, localidade, uf } = res.data as ViaCepResponse;

        this.$emit("cep", {
          zipcode: this.cep,
          neighborhood: bairro,
          street: logradouro,
          city: localidade,
          state: uf,
        } as Address);
      });
    },
  },
});
</script>
