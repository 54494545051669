<template>
  <t-text-input
    v-bind="$attrs"
    ref="field"
    v-mask="'###.###.###-##'"
    :label="noLabel ? '' : 'CPF'"
    :rules="validationRules"
    type="text"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import { cpf } from "cpf-cnpj-validator";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TCpfInput",

  components: {
    TTextInput,
  },

  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<((v: string) => boolean | string)[] | []>,
      default: () => [],
    },
  },

  computed: {
    validationRules(): ((v: string) => boolean | string)[] | [] {
      const cpfValidateRule = (v: string): boolean | string => cpf.isValid(v, true) || "CPF inválido";

      return [...this.rules, cpfValidateRule];
    },
  },

  methods: {
    validate(): boolean {
      const field = this.$refs.field as Vue & { validate(): boolean };
      return field.validate();
    },
  },
});
</script>
