import { TrackableEvent } from "@/analytics/TrackableEvents";
import { MiddlewareContext } from ".";
import { analytics as plugin } from "@/plugins/analytics";
import store from "@/store";

/**
 * Fire page view event to [AnalyticsStrategies] on base producer (IDV) and selected producers
 *
 * @param context `MiddlewareContext`
 * @returns `void`
 */
export function analytics(context: MiddlewareContext) {
  const excluded = [
    "PaymentCheckout",
  ];

  if (context.to.name !== null && context.to.name !== undefined && excluded.includes(context.to.name)) {
    return context.next();
  }

  const user = store.state.user.data;

  plugin.collect(
    TrackableEvent.PageView,
    {
      params: {
        user_id: user.id,
        app_commit: store.state.app.commit,
        app_version: store.state.app.version,
        standalone: window.matchMedia("(display-mode: standalone)").matches,
      },
    },
  );

  return context.next();
}
