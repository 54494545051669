<template>
  <t-text-input
    v-mask="mask"
    v-bind="$attrs"
    :hint="format"
    :rules="validationRules"
    :value="value"
    type="text"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import { parse, isValid } from "date-fns";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TDateInput",

  components: {
    TTextInput,
  },

  props: {
    // use mask format from v-mask lib
    mask: {
      type: String,
      default: "##/##/####",
    },
    format: {
      type: String,
      default: "dd/mm/aaaa",
    },
    rules: {
      type: Array as PropType<((v?: string) => boolean | string)[] | []>,
      default: () => [],
    },
    value: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    validationRules(): ((date?: string) => boolean | string)[] | [] {
      const validateDateFormat = (date?: string): boolean | string => {
        if (!date) return true;

        const regex = /^([0-9]{2})\/[0-9]{2}\/([0-9]{4})$/g;

        return regex.test(date) || `A data precisa estar no formato ${this.format}`;
      };

      const validateDate = (date?: string): boolean | string => {
        if (!date) return true;

        const parsedDate = parse(date, "dd/MM/yyyy", new Date());
        return isValid(parsedDate) || "Data inválida";
      };

      return [...this.rules, validateDateFormat, validateDate];
    },
  },
});
</script>
