<template>
  <t-card max-width="600" class="d-flex flex-column justify-start">
    <v-card-title>
      <span v-if="dialog.hasTitle">ACESSAR CONTA</span>
      <v-spacer />
      <v-btn v-if="dialog.hasCloseButton" icon>
        <v-icon @click="close">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="login"
        @keypress.enter="login"
      >
        <v-row justify="center" class="my-4">
          <v-col cols="12">
            <div class="d-flex justify-center">
              <img
                style="width: 75%"
                alt="ClickerTicket Logo"
                :src="imageSrc"
                transition="scale-transition"
              >
            </div>
          </v-col>
        </v-row>
        <p>Informe os dados abaixo para ter acesso a sua conta:</p>
        <t-text-input
          v-model="email"
          label="E-mail"
          type="email"
          outlined
          required
        />
        <t-text-input
          v-model="password"
          label="Senha"
          type="password"
          outlined
          required
        />
        <v-btn
          color="success"
          block
          type="submit"
          class="py-5"
          :loading="loading"
          :disabled="!valid"
        >
          Acessar
        </v-btn>
      </v-form>
    </v-card-text>
    <v-spacer />
  </t-card>
</template>

<script lang="ts">
import Vue from "vue";
import TCard from "@/components/core/TCard.vue";
import TTextInput from "@/components/core/TTextInput.vue";

import { DialogConfig } from "@/store/modules/dialog";
import Logo from "@/assets/img/logo-onda-verde.png";
import getAccessToken from "@/api/requests/getAccessToken";
import getUserData from "@/api/requests/getUserData";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";

export default Vue.extend({
  name: "LoginSocio",

  components: {
    TCard,
    TTextInput,
  },

  data: () => ({
    valid: false,
    email: "",
    loading: false,
    password: "",
    imageSrc: Logo,
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    dialog(): DialogConfig {
      return this.$store.state.dialog.config;
    },
  },

  methods: {
    async login() {
      if (!this.form.validate()) return;

      this.loading = true;

      try {
        const { email, password } = this;

        const { data } = await getAccessToken(email, password);

        this.$store.commit("user/setToken", data);

        const user = await (await getUserData()).data;

        this.$store.commit("user/setUser", user);

        this.$snackbar("Login feito com sucesso!", "success");

        this.loading = false;
        this.close();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          if (error.response?.status == 400) {
            this.$snackbar("E-mail ou senha incorretos!", "error");
          } else {
            this.$snackbar("Não foi possível realizar seu login, entre em contato com o suporte.", "error");
          }
        } else {
          this.$snackbar("Não foi possível realizar seu login, entre em contato com o suporte.", "error");
        }
      } finally {
        this.loading = false;
      }
    },
    register() {
      this.$dialog.show("/register");
    },
    forgotPassword() {
      this.$dialog.show("/recover");
    },
    close() {
      this.$dialog.hide();
    },
  },
});
</script>

<style lang="scss">
.light-grey {
  background-color: #ccc !important;
}

.clickable {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}
</style>
