export default function pad(
  input: string,
  length: number,
  padCharacter = "0",
  padDirection: "left" | "right" = "left",
): string {
  if (padDirection == "left") {
    return input.padStart(length, padCharacter);
  } else if (padDirection == "right") {
    return input.padStart(length, padCharacter);
  }

  return "Direction Error";
}
