<template>
  <v-btn
    class="text-uppercase"
    v-bind="$attrs"
    depressed
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "TBtn",
});
</script>
