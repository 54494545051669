<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="verify"
    @keypress.enter="verify"
  >
    <p class="text-justify mb-5">
      Caso você esteja aqui pela primeira vez comprando ingressos conosco e por este motivo ainda não tenha um cadastro
      em nossa base, prossiga informando seu CPF no campo abaixo:
    </p>

    <t-cpf-input v-model="user.document" required />

    <t-btn
      block
      color="success"
      type="submit"
      class="heading--text"
      large
      :loading="loading"
      :disabled="!valid"
    >
      Cadastrar
    </t-btn>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import User from "@/types/User";

import userExists from "@/api/requests/userExists";

import TBtn from "@/components/core/TBtn.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";

export default Vue.extend({
  name: "FormNewUser",

  components: {
    TBtn,
    TCpfInput,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },

  data: () => ({
    valid: true,
    loading: false,
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  methods: {
    async verify() {
      if (!this.form.validate()) return;

      this.loading = true;
      this.$emit("verified", await userExists("document", this.user.document));
      this.loading = false;
    },
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
  },
});
</script>
