import Event from "@/types/Event";

export interface State {
  data: Event[];
  selected: Event;
}

const state: State = {
  data: [] as Event[],
  selected: {} as Event,
};

const mutations = {
  setEvents(state: State, events: Event[]): void {
    state.data = [ ...events ];
  },
  setSelectedEvent(state: State, event: Event): void {
    state.selected = event;
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
};
