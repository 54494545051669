import { AnalyticsStrategy } from "@/contracts/AnalyticsStrategy";
import { GoogleAnalyticsStrategy, MetaPixelAnalyticsStrategy } from "./Strategies";

const instances: AnalyticsStrategy[] = [];

export class Analytics {
  static make(identifier: string): AnalyticsStrategy {
    const match = instances.find(i => i.identifier === identifier);

    if (match !== undefined) {
      return match;
    }

    let instance: AnalyticsStrategy;

    if (identifier.includes("G-")) {
      console.log(`[Analytics] - Registered new GA4 ${identifier}`);
      instance = new GoogleAnalyticsStrategy(identifier);
    } else {
      console.log(`[Analytics] - Registered new Meta Pixel ${identifier}`);
      instance = new MetaPixelAnalyticsStrategy(identifier);
    }

    instances.push(instance);

    return instance;
  }
}
