import { RootState } from "@/store";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import { Store } from "vuex";

export interface MiddlewareContext {
  to: Route;
  from: Route;
  next: NavigationGuardNext;
  store: Store<RootState>;
}

export type Middleware = (context: MiddlewareContext) => void | Promise<void>;

/**
 * Creates a pipeline of middlewares to ensure sequential and finite run of middlewares
 * on a FIFO order.
 *
 * @param context `MiddlewareContext`
 * @param middlewares queue of middlewares to be applied on the execution `Middleware`
 * @param index index of the current middleware running `number`
 * @returns
 */
export function pipeline(context: MiddlewareContext, middlewares: Middleware[], index: number) {
  const next = middlewares[index];

  if (!next) {
    return context.next;
  }

  return () => next({ ...context, next: pipeline(context, middlewares, index + 1) });
}

/**
 * Middleware namespace for public API
 */
export default class MiddlewareProvider {
  /**
   * Apply middlewares on a stack before each router. Global middlewares run first, then route-specific middlewares.
   *
   * @param router router to which this plugin must be registered `VueRouter`
   * @param store TODO: change this for a generic "options" param. `Store`
   * @param globals middlewares applied to all routes. `Middleware[]`
   */
  public static register(router: VueRouter, store: Store<RootState>, globals: Middleware[] = []) {
    router.beforeEach(async (to, from, next) => {
      const middlewares = [
        ...globals,
        ...to.meta?.middlewares as Middleware[] ?? [],
      ];

      if (middlewares.length === 0) {
        return next();
      }

      const context = {
        to,
        from,
        next,
        store,
      };

      // Run first middleware passing context and the second middleware as next
      return middlewares[0]({
        ...context,
        next: pipeline(context, middlewares, 1),
      });
    });
  }
}
