import { ActionContext } from "vuex";

import { RootState } from "..";
import { producers, initial } from "@/config/producers";
import { WhitelabelProducer } from "@/types/Producer";
import { TrackableEvent } from "@/analytics/TrackableEvents";
import { Analytics } from "@/analytics";

export interface State {
  producers: WhitelabelProducer[];
  selected: WhitelabelProducer;
  default: WhitelabelProducer;
}

const state: State = {
  producers,
  selected: initial,
  default: initial,
};

const mutations = {
  set(state: State, producer: WhitelabelProducer): void {
    state.selected = producer;
  },
  reset(state: State): void {
    state.producers = producers;
  },
};

const actions = {
  setBySlug(context: ActionContext<State, RootState>, slug: string): void {
    const found = context.state.producers.find(p => p.slug === slug);

    if (found === undefined) {
      const def = state.producers.find(p => p.slug === "");
      context.commit("set", def);
    } else {
      context.commit("set", found);
    }
  },
  analytics(
    context: ActionContext<State, RootState>,
    { producer_id, action, params }: { producer_id: string; action: TrackableEvent; params: Record<string, string> },
  ): void {
    const producer = context.state.producers.find(p => p.id == producer_id);

    if (!producer?.analytics) return;

    producer.analytics.map(a => Analytics.make(a).send(action, params));
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
};
