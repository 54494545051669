<template>
  <nav>
    <v-app-bar
      v-if="!isMobile"
      app
      color="dark-background"
      dark
      height="86"
    >
      <v-row no-gutters justify="center">
        <v-col
          cols="12"
          md="10"
          class="v-toolbar__content d-flex justify-space-between"
          style="height: 64px"
        >
          <t-clicker-ticket-logo-header style="width: 160px" @click="$route.path !== '/' && $router.push('/')" />

          <v-spacer />

          <v-toolbar-items>
            <v-btn
              v-for="{ label, icon, callback } in menus"
              :key="label"
              text
              @click="callback"
            >
              <v-icon left>
                {{ icon }}
              </v-icon>
              {{ label }}
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
    </v-app-bar>
    <div v-else>
      <v-app-bar
        app
        dark
        color="dark-background"
        extension-height="50"
        height="50px"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

        <v-spacer />

        <v-app-bar-title>
          <t-clicker-ticket-logo-header style="width: 120px" @click="$route.path !== '/' && $router.push('/')" />
        </v-app-bar-title>

        <v-spacer />

        <v-btn v-if="!logged" icon @click="() => $dialog.show('/login')">
          <v-icon> mdi-account-outline </v-icon>
        </v-btn>
        <v-btn v-else icon @click="$router.push('/user/data')">
          <v-icon> mdi-account </v-icon>
        </v-btn>

        <template #extension>
          <v-sheet color="primary" class="py-1" style="width: 100%">
            <v-row align="center">
              <v-col>
                <v-btn
                  v-if="logged && showTicketsMenu"
                  color="primary"
                  class="ps-0"
                  style="text-transform: capitalize"
                  depressed
                  @click="$router.push('/ticket/payments')"
                >
                  <v-icon class="me-2">
                    mdi-ticket-confirmation-outline
                  </v-icon>
                  Meus Ingressos
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                v-if="$store.state.user.logged"
                cols="4"
                class="text-end"
                style="font-size: 14px; font-weight: 500"
              >
                Olá
                {{
                  $store.state.user.data.name ? $store.state.user.data.name.split(" ")[0] : $store.state.user.data.name
                }}!
              </v-col>
            </v-row>
          </v-sheet>
        </template>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app left>
        <v-toolbar
          flat
          dense
          color="primary"
          dark
        >
          <v-toolbar-title v-if="$store.state.user.logged">
            Olá
            {{ $store.state.user.data.name ? $store.state.user.data.name.split(" ")[0] : $store.state.user.data.name }}
          </v-toolbar-title>
          <v-toolbar-title v-else />
        </v-toolbar>
        <v-list nav dense>
          <v-list-item-group v-model="menu">
            <v-list-item
              v-for="{ label, icon, classes, callback } in menus"
              :key="label"
              :class="classes"
              @click="
                drawer = false;
                callback();
              "
            >
              <v-icon left>
                {{ icon }}
              </v-icon>
              <v-list-item-title> {{ label }} </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </nav>
</template>

<script lang="ts">
import Vue from "vue";
import TClickerTicketLogoHeader from "@/components/core/TClickerTicketLogoHeader.vue";
import AppBarItem from "@/types/AppBarItem";

export default Vue.extend({
  name: "TheAppBar",
  components: {
    TClickerTicketLogoHeader,
  },
  data() {
    return {
      menu: null,
      drawer: false,
      modal: false,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    name(): string {
      return this.$store.state.user.data.name;
    },
    logged(): boolean {
      return this.$store.state.user.data.name !== undefined;
    },
    showTicketsMenu(): boolean {
      if (this.$route.name === null) return true;
      if (this.$route.name === undefined) return true;

      const exclude = [ "" ];

      return !exclude.includes(this.$route.name);
    },
    menus(): AppBarItem[] {
      if (this.isMobile) {
        return [
          {
            label: "Home",
            icon: "mdi-home-outline",
            callback: () => this.$router.push("/"),
            condition: true,
          },
          {
            label: "Meus Ingressos",
            icon: "mdi-ticket-confirmation-outline",
            callback: () => this.$router.push("/ticket/payments"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Meus Dados",
            icon: "mdi-pencil",
            callback: () => this.$router.push("/user/data"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Acesse Sua Conta",
            icon: "mdi-account-outline",
            callback: () => this.$dialog.show("/login"),
            condition: !this.$store.state.user.logged,
          },
          {
            label: "Fale conosco",
            icon: "mdi-chat-question-outline",
            callback: () => this.$router.push("/suporte"),
            condition: true,
          },
          {
            label: "Instalar APP",
            icon: "mdi-cellphone-arrow-down",
            classes: "browser-only",
            callback: () => this.$store.commit("app/pwa"),
            condition: window.pwa !== null && !this.$store.state.app.pwa.installed,
          },
          {
            label: `Sair`,
            icon: "mdi-logout",
            callback: () => {
              this.$store.commit("user/logout");
              this.$store.commit("cart/reset");
              this.$router.push("/");
            },
            condition: this.$store.state.user.logged,
          },
        ].filter((item: AppBarItem) => item.condition);
      } else {
        return [
          {
            label: "Home",
            icon: "mdi-home-outline",
            callback: () => this.$router.push("/"),
            condition: true,
          },
          {
            label: "Meus Ingressos",
            icon: "mdi-ticket-confirmation-outline",
            callback: () => this.$router.push("/ticket/payments"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Meus Dados",
            icon: "mdi-pencil",
            callback: () => this.$router.push("/user/data"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Acesse Sua Conta",
            icon: "mdi-account-outline",
            callback: () => this.$dialog.show("/login"),
            condition: !this.$store.state.user.logged,
          },
          {
            label: `Olá ${
              this.$store.state.user.data.name
                ? this.$store.state.user.data.name.split(" ")[0]
                : this.$store.state.user.data.name
            }!`,
            icon: "mdi-account-outline",
            callback: () => true,
            condition: this.$store.state.user.logged,
          },
          {
            label: `Sair`,
            icon: "mdi-logout",
            callback: () => {
              this.$store.commit("user/logout");
              this.$store.commit("cart/reset");
              this.$router.push("/");
            },
            condition: this.$store.state.user.logged,
          },
        ].filter((item: AppBarItem) => item.condition);
      }
    },
  },
});
</script>

<style>
.v-toolbar__extension {
  background-color: var(--primary-color) !important;
}
</style>
