import { themes } from "@/assets/themes";
import { WhitelabelProducer } from "@/types/Producer";

export const producers: WhitelabelProducer[] = [
  {
    id: "0",
    slug: "",
    title: "Ingresso de Vantagens",
    theme: themes.base,
    analytics: [
      "G-3FRE63HPHD",
      "230989696734366",
    ],
  },
  {
    id: "655a49a0-5408-11ee-a5af-2388dbba5fcc",
    slug: "america",
    title: "América FC",
    theme: themes.america,
  },
  {
    id: "f22a4430-c4d9-11ec-b892-ef25fe858e6b",
    slug: "jec-futsal",
    title: "JEC Krona",
    theme: themes.jec,
  },
  {
    id: "68dbf0a0-ca91-11e9-83a8-8b4b91c36339",
    slug: "jec",
    title: "JEC",
    theme: themes.jec,
  },
  {
    id: "c0a43340-b476-11ee-901b-bd30cdc86c8d",
    slug: "barra",
    title: "Barra FC",
    theme: themes.barra,
    analytics: [
      "218154797957065",
    ],
  },
  {
    id: "0de57220-b12b-11e8-8ee6-5f76827d02a6",
    slug: "dataclick",
    title: "DTKSports",
    theme: themes.base,
    analytics: [
      "230989696734366",
    ],
  },
  {
    id: "f466b020-69ef-11ef-9fd7-cdb119d38fc8",
    slug: "vila-isabel",
    title: "Vila Isabel",
    theme: themes.base,
    analytics: [
      "970748381489336",
    ],
  },
  {
    id: "0a033c10-8c78-11ef-b29e-518037e34d57",
    slug: "acl-entretenimento",
    title: "ACL Entretenimento",
    theme: themes.base,
    analytics: [
      "1270048361091568",
    ],
  },
];

export const initial = producers[0];
