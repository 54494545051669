<template>
  <v-app>
    <snackbar />
    <the-dialog />
    <the-app-bar />
    <v-main>
      <router-view />
    </v-main>
    <the-cookie-dialog />
    <the-footer />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";

import Snackbar from "@/components/core/Snackbar.vue";
import TheDialog from "@/components/core/TheDialog.vue";
import TheAppBar from "@/components/core/TheAppBar.vue";
import TheFooter from "@/components/core/TheFooter.vue";
import TheCookieDialog from "@/components/core/TheCookieDialog.vue";
import { WhitelabelProducer } from "./types/Producer";

export default Vue.extend({
  name: "App",
  components: {
    Snackbar,
    TheAppBar,
    TheFooter,
    TheCookieDialog,
    TheDialog,
  },

  data: () => ({
    //
  }),

  computed: {
    producer(): WhitelabelProducer {
      return this.$store.state.producers.selected as WhitelabelProducer;
    },
  },

  watch: {
    producer(old: WhitelabelProducer, _new: WhitelabelProducer) {
      this.changeTheme(_new.theme);
    },
  },

  mounted() {
    this.changeTheme(this.producer.theme);
  },

  methods: {
    changeTheme(theme: { light: Partial<unknown> }) {
      Object.keys(theme.light).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = theme.light[i];
      });
    },
  },
});
</script>

<style>
#app {
  overflow-x: hidden;
}

body,
html {
  scroll-behavior: smooth;
}
</style>
