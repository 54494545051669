import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import { ActionContext } from "vuex";
import { RootState } from "..";

export interface State {
  selectedOrder: Order;
  orders: Order[];
  editing: {
    ticket: PaymentItem | null;
    action: "edit" | "register";
  };
  prompting: {
    ticket: PaymentItem | null;
    text: string;
    callback: (value: boolean) => void;
  };
}

const state: State = {
  selectedOrder: {} as Order,
  orders: [] as Order[],
  editing: {
    ticket: null,
    action: "edit",
  },
  prompting: {
    ticket: null,
    text: "",
    callback: () => true,
  },
};

const mutations = {
  setSelectedOrder(state: State, order: Order): void {
    state.selectedOrder = { ...order };
  },
  setOrders(state: State, orders: Order[]): void {
    state.orders = orders;
  },
  setTicketCarrierChangeDate(state: State, payload: { ticket: PaymentItem; date: string }): void {
    const { ticket, date } = payload;
    const order = state.orders.find(o => o.id === ticket.compra_id);
    if (order) {
      order.items.find(i => i.id === ticket.id)!.carrier_change_date = date;
    }
  },
  setEditingTicket(state: State, ticket: PaymentItem): void {
    state.editing.ticket = ticket;
  },
  setPrompt(state: State, ticket: PaymentItem): void {
    state.prompting.ticket = ticket;
  },
};

const actions = {
  prompt(
    context: ActionContext<State, RootState>,
    { item, text, callback }: { item: PaymentItem; text: string; callback: (val: boolean) => boolean },
  ): void {
    context.state.prompting = {
      ticket: item,
      text,
      callback,
    };
  },
  clearPrompt(context: ActionContext<State, RootState>): void {
    context.state.prompting = {
      ticket: null,
      text: "",
      callback: () => true,
    };
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
};
