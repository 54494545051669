import { RouteConfig } from "vue-router";
import store from "@/store";
import { auth } from "../middlewares/auth";

const TicketList = () => import(/* webpackChunkName: "ticket-buy" */ "@/views/Ticket/PaymentDetails.vue");

const Payments = () => import(/* webpackChunkName: "ticket-buy-details" */ "@/views/Ticket/PaymentListing.vue");

const UserData = () => import(/* webpackChunkName: "user-data" */ "@/views/User/UserData.vue");

const routes: RouteConfig[] = [
  {
    path: "/:company?/ticket/payments",
    name: "Payments",
    component: Payments,
    meta: {
      middlewares: [ auth ],
    },
  },
  {
    path: "/:company?/ticket/list/:id",
    name: "TicketList",
    component: TicketList,
    meta: {
      middlewares: [ auth ],
    },
    beforeEnter(to, from, next) {
      store.commit("cart/reset");
      store.commit("cart/setEventId", null);

      if (Object.keys(store.state.ticket.selectedOrder).length == 0) {
        return next({ name: "Payments" });
      }

      next();
    },
  },
  {
    path: "/:company?/user/data",
    name: "UserData",
    component: UserData,
    meta: {
      middlewares: [ auth ],
    },
  },
];

export default routes;
