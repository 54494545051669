import api from "@/api";
import { PasswordToken } from "./getPasswordRecoveryToken";

export default async function recoverPassword(
  token: PasswordToken,
  password: string,
  document: string,
): Promise<boolean> {
  document = document.replace(/\D/g, "");

  await api.post("/api/password/recover", {
    document,
    token: token.token,
    password,
  });

  return true;
}
