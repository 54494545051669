<template>
  <span class="display-adapter">
    <slot v-if="$slots.mobile && isMobile" name="mobile" />
    <slot v-if="$slots.desktop && !isMobile" name="desktop" />
  </span>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },
});
</script>
