<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="py-4"
    @submit.prevent="submit"
    @keypress.enter="submit"
  >
    <v-row justify="center">
      <v-col cols="12" md="6">
        <t-cep-input v-model="zipcode" @cep="fillForm" />
      </v-col>

      <v-col cols="12" md="6">
        <t-text-input
          label="Bairro"
          required
          hide-details="auto"
          :value="address.neighborhood"
          :disabled="!zipcode"
          @input="update('neighborhood', $event)"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-text-input
          label="Estado"
          :value="address.state"
          hide-details="auto"
          required
          disabled
          @input="update('state', $event), (address.city = null)"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-text-input
          label="Cidade"
          :value="address.city"
          hide-details="auto"
          required
          disabled
          @input="update('city', $event)"
        />
      </v-col>

      <v-col cols="12" md="8">
        <t-text-input
          label="Endereço"
          required
          hide-details="auto"
          :value="address.street"
          :disabled="!zipcode"
          @input="update('street', $event)"
        />
      </v-col>

      <v-col cols="12" md="4">
        <t-text-input
          label="Número"
          required
          hide-details="auto"
          :value="address.number"
          @input="update('number', $event)"
        />
      </v-col>

      <v-col cols="12">
        <t-text-input
          label="Complemento (opcional)"
          hide-details="auto"
          :value="address.complement"
          @input="update('complement', $event)"
        />
      </v-col>

      <v-col cols="12" class="pt-0">
        <v-checkbox v-model="policy" hide-details="">
          <template #label>
            <div>
              Aceito e concordo com os
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <a
                    target="_blank"
                    href="termos"
                    @click.stop
                    v-on="on"
                  > Termos de Uso </a>
                </template>
                Abre em nova página
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <t-btn
          color="success"
          block
          type="submit"
          class="heading--text"
          large
          :loading="loading"
          :disabled="!valid || !policy"
        >
          Avançar
        </t-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import Address from "@/types/Address";

import TBtn from "@/components/core/TBtn.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TCepInput from "@/components/core/TCepInput.vue";

export default Vue.extend({
  name: "FormAddress",

  components: {
    TBtn,
    TTextInput,
    TCepInput,
  },

  props: {
    value: {
      type: Object as PropType<Address>,
      default: () => ({} as Address),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    valid: true,
    policy: false,
  }),

  computed: {
    zipcode: {
      get(): string {
        return this.address.zipcode;
      },
      set(zipcode: string): void {
        this.update("zipcode", zipcode);
      },
    },
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    address(): Address {
      return this.value;
    },
  },

  methods: {
    submit() {
      if (!this.form.validate()) return;

      this.$emit("submit");
    },
    update(prop: string, value: string) {
      this.$emit("input", { ...this.address, [prop]: value });
    },
    fillForm(address: Address) {
      this.$emit("input", { ...this.address, ...address });
    },
  },
});
</script>
