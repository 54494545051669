import Vue from "vue";
import Vuex from "vuex";

import modules, { State as ModuleStates } from "./modules";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export type RootState = Record<string, unknown> & ModuleStates;

const persistence = new VuexPersistence({
  storage: window.localStorage,
  modules: ["user", "ticket", "dialog", "events", "app", "cart", "producers", "mapping"],
});

const store = new Vuex.Store<RootState>({
  state: undefined,
  mutations: {},
  actions: {},
  // TODO: adicionar tipagem correta
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
  modules: modules as any,
  plugins: [ persistence.plugin ],
});

store.commit("producers/reset");
store.commit("app/update");

export default store;
