<template>
  <v-bottom-sheet v-model="show" @click:outside="$store.commit('app/cookie', false)">
    <v-col>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>Aviso de Cookies</v-card-title>
            <v-card-text>
              Nós e os terceiros selecionados usamos cookies ou tecnologias similares para finalidades técnicas e, com
              seu consentimento, para outras finalidades conforme especificado na política de privacidade. Use o botão
              “Aceitar” para consentir. Use o botão “Recusar” ou feche este aviso para continuar sem aceitar.
            </v-card-text>
            <v-card-actions>
              <v-row justify="end">
                <v-col cols="4" class="d-flex justify-end mb-4">
                  <v-btn class="mx-4" color="error" @click="$store.commit('app/cookie', false)">
                    Recusar
                  </v-btn>
                  <v-btn class="mx-4" color="success" @click="$store.commit('app/cookie', true)">
                    Aceitar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-bottom-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  computed: {
    show: {
      get() {
        return !this.$store.state.app.cookies.prompted;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    submit(value: boolean) {
      this.$store.commit("app/cookie", value);
    },
  },
});
</script>
