import { MiddlewareContext } from ".";

/**
 * Opens login dialog on routes that requires authentication
 * @param context `MiddlewareContext`
 * @returns `Promise<void>`
 */
export function auth(context: MiddlewareContext) {
  if (context.store.state.user.logged) {
    return context.next();
  }

  return context.next("/");
}
