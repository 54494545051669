<template>
  <v-card class="grey lighten-3">
    <v-card-title>
      <span v-if="dialog.config.hasTitle">INFORMAÇÕES DO EVENTO</span>
      <v-spacer />
      <v-btn v-if="dialog.config.hasCloseButton" icon>
        <v-icon @click="close">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <span v-html="event.sobre" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { DialogConfig } from "@/store/modules/dialog";
import Event from "@/types/Event";
import Vue from "vue";
export default Vue.extend({
  computed: {
    event(): Event {
      return this.$store.state.events.selected;
    },
    dialog(): DialogConfig {
      return this.$store.state.dialog;
    },
  },
  methods: {
    close() {
      this.$dialog.hide();
    },
  },
});
</script>

<style lang="scss">
.light-grey {
  background-color: #ccc !important;
}

.clickable {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}
</style>
