<template>
  <t-text-input
    v-bind="$attrs"
    ref="field"
    v-mask="phoneMask"
    :label="noLabel ? '' : label"
    :rules="validationRules"
    :value="value"
    type="text"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TPhoneInput",

  components: {
    TTextInput,
  },

  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Fone",
    },
    rules: {
      type: Array as PropType<((v: string) => boolean | string)[] | []>,
      default: () => [],
    },
    value: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    phoneMask(): string {
      return this.getPhoneNumbers(this.value).length > 10 ? "(##) #####-####" : "(##) ####-####";
    },
    validationRules(): ((v: string) => boolean | string)[] | [] {
      const validatePhone = (phone?: string): boolean | string => {
        const phoneLength = this.getPhoneNumbers(phone).length;

        return phoneLength === 11 || "O número de telefone deve conter no mínimo 11 dígitos";
      };

      return [...this.rules, validatePhone];
    },
  },

  methods: {
    getPhoneNumbers(phone?: string): string {
      const regex = /[\d]/g;

      return phone?.match(regex)?.join("") || "";
    },
    validate(): boolean {
      const field = this.$refs.field as Vue & { validate(): boolean };
      return field.validate();
    },
  },
});
</script>
