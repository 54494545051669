<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    @keypress.enter="submit"
  >
    <p class="text-justify mb-5">
      Qual método deseja utilizar para recuperar sua senha?
    </p>

    <v-list-item-group v-model="selected">
      <v-list-item
        v-for="method in methods"
        :key="method.name"
        class="mb-4 rounded"
        style="border: 1px solid #ccc"
      >
        <template #default="{ active }">
          <v-list-item-action>
            <v-checkbox :input-value="active" color="primary" />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title> {{ method.value }} </v-list-item-title>
            <v-list-item-subtitle> {{ method.name }} </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>

    <t-btn
      block
      color="success"
      type="submit"
      class="heading--text"
      large
      :loading="loading"
      :disabled="!valid"
    >
      Avançar
    </t-btn>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import sendPasswordRecoveryCode from "@/api/requests/sendPasswordRecovery";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";
import RecoveryMethod from "@/types/RecoveryMethod";

export default Vue.extend({
  name: "FormGetRecoverCode",

  components: {
    TBtn,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
    methods: {
      type: Array as PropType<RecoveryMethod[]>,
      required: true,
    },
  },

  data: () => ({
    valid: true,
    loading: false,
    selected: 0,
    rules: {
      email: [
        (v: string) => !!v || "O campo e-mail é obrigatório",
        (v: string) => /.+@.+\..+/.test(v) || "O e-mail digitado é inválido",
      ],
    },
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  methods: {
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
    async submit() {
      try {
        if (this.form.validate()) {
          this.loading = true;
          await sendPasswordRecoveryCode(this.user.document, this.methods[this.selected]);
          this.$emit("submit");
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          if (error.response?.data.message) {
            this.$snackbar(error.response?.data.message, "error");
          } else {
            this.$snackbar(error.message, "error");
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
