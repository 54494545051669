import { Analytics } from "@/analytics";
import { TrackableEvent } from "@/analytics/TrackableEvents";
import store from "@/store";

export const analytics = {
  /**
   * Fires an event for possible list of targets composed by the default project's strategies and any number of
   * producer-based strategies
   *
   * @param event enumeration representing one of the many event types supported by the project
   * @param params map of parameters that should be passed to the fired event
   */
  collect(event: TrackableEvent, { producer_id = undefined, params }: { producer_id?: string; params: Record<string, unknown> }) {
    const base = store.state.producers.default;

    const targets = [
      base,
    ];

    if (producer_id != null) {
      const producer = store.state.producers.producers.find(p => p.id === producer_id);
      if (producer !== undefined) targets.push(producer);
    }

    targets.forEach(
      t => t.analytics?.forEach(
        a => Analytics.make(a).send(event, params),
      ),
    );
  },
};

export default { ...analytics };

declare module "vue/types/vue" {
  interface Vue {
    $analytics: {
      collect(event: TrackableEvent, { producer_id, params }: { producer_id?: string; params: Record<string, unknown> }): void;
    };
  }
}
