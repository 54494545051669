<template>
  <v-card
    v-bind="$attrs"
    flat
    rounded="lg"
    class="content-background t-card-margin"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "TCard",
});
</script>

<style scoped>
.t-card-margin {
  border-style: solid;
  border-width: 1px;
  border-color: #848484 !important;
}
</style>
