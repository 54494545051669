export type LoaderType = "global" | "button";

export interface State {
  counters: Record<LoaderType, number>;
}

const state: State = {
  counters: {
    global: 0,
    button: 0,
  },
};

const mutations = {
  increment(state: State, type: LoaderType = "global"): void {
    state.counters[type]++;
  },
  decrement(state: State, type: LoaderType = "global"): void {
    if (state.counters[type] === 0) return;

    state.counters[type]--;
  },
};

const getters = {
  loading:
    (state: State) =>
      (type: LoaderType = "global"): boolean =>
        state.counters[type] !== 0,
};

export const store = {
  namespaced: true,
  state,
  mutations,
  getters,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $loader: {
      loading(type?: LoaderType): boolean;
      increment(type?: LoaderType): void;
      decrement(type?: LoaderType): void;
    };
  }
}
