import { registerSW } from "virtual:pwa-register";

registerSW({
  immediate: true,
  onRegisteredSW(url: string, registration: ServiceWorkerRegistration | undefined) {
    console.log("[pwa.ts] - Service worker registered");

    if (!registration) return;

    setInterval(() => checkForUpdate(url, registration), 30 * 60 * 1000);
  },
});

async function checkForUpdate(url: string, registration: ServiceWorkerRegistration) {
  console.log("[pwa.ts] - Checking for updates");

  if (registration.installing || !navigator) return;

  if (("connection" in navigator) && !navigator.onLine) return;

  const response = await fetch(url, {
    cache: "no-store",
    headers: {
      "cache": "no-store",
      "cache-controle": "no-cache",
    },
  });

  console.log("[pwa.ts] - Update found, new content will be downloaded");

  if (response.status === 200) registration.update();
}
