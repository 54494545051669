<template>
  <t-card max-width="600" class="d-flex flex-column justify-start">
    <v-card-title>
      <span v-if="dialog.config.hasTitle">ACESSO SÓCIO-TORCEDOR</span>
      <v-spacer />
      <v-btn v-if="dialog.config.hasCloseButton" icon>
        <v-icon @click="close">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row justify="center" class="mt-4 mb-16">
        <v-col cols="12">
          <div class="d-flex justify-center">
            <img
              style="width: 75%"
              alt="ClickerTicket Logo"
              :src="imageSrc"
              transition="scale-transition"
            >
          </div>
        </v-col>
      </v-row>
      <v-btn
        color="#3c6745"
        block
        type="submit"
        class="mt-8 white--text"
        @click="login"
      >
        Sou Sócio Onda Verde
      </v-btn>
      <v-btn
        color="light-grey"
        block
        type="submit"
        class="my-8"
        href="https://socioondaverde.com.br/planos"
        target="_blank"
      >
        Quero ser Sócio Onda Verde
      </v-btn>
    </v-card-text>
    <v-spacer />
  </t-card>
</template>

<script lang="ts">
import Vue from "vue";
import TCard from "@/components/core/TCard.vue";
import { DialogConfig } from "@/store/modules/dialog";
import Logo from "@/assets/img/logo-onda-verde.png";

export default Vue.extend({
  name: "AcessoSocio",

  components: {
    TCard,
  },

  data: () => ({
    valid: false,
    email: "",
    loading: false,
    password: "",
    imageSrc: Logo,
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    dialog(): DialogConfig {
      return this.$store.state.dialog;
    },
  },

  methods: {
    login() {
      this.$dialog.show("/login/socio");
    },
    close() {
      this.$dialog.hide();
    },
  },
});
</script>

<style lang="scss">
.light-grey {
  background-color: #ccc !important;
}

.clickable {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}
</style>
