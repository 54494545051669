import { VuetifyThemeVariant } from "vuetify/types/services/theme";

const light: Partial<VuetifyThemeVariant> = {
  "primary": "#0D2B5C",
  "secondary": "#1F49A0",
  "tertiary": "#FFF200",
  "dark-background": "#242424",
  "background": "#eee",
  "content-background": "#F8F8F8",
  "heading": "#fff",
  "body": "#D4D4D4",
};

export default { light };
