<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="py-4"
    @submit.prevent="submit"
    @keypress.enter="submit"
  >
    <v-row justify="center">
      <v-col cols="12" md="6">
        <t-cpf-input
          v-model="user.document"
          hide-details="auto"
          required
          disabled
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-select
          v-model="user.gender_id"
          label="Gênero"
          required
          hide-details="auto"
          :items="genders"
          item-text="name"
          item-value="id"
          @input="update('gender', $event)"
        />
      </v-col>

      <v-col cols="12" md="12">
        <t-text-input
          label="Nome Completo"
          required
          hide-details="auto"
          :rules="nameRules"
          :value="user.name"
          @input="update('name', $event)"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-phone-input
          required
          hide-details="auto"
          :value="user.cellphone"
          label="Telefone"
          @input="update('cellphone', $event)"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-date-input
          label="Data de Nascimento"
          hide-details="auto"
          required
          :value="user.birth_date"
          @input="update('birth_date', $event)"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-email-input
          required
          hide-details="auto"
          :value="user.email"
          :error-messages="emailErrorBag"
          @input="onEmailInput"
          @blur="checkEmail"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-text-input
          v-model="confirmEmail"
          label="Confirme seu e-mail "
          :rules="confirmEmailRules"
          hide-details="auto"
          @paste.prevent
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-password-input
          label="Senha"
          required
          hide-details="auto"
          :show="showPassword"
          :value="user.password"
          @click:append="showPassword = !showPassword"
          @input="update('password', $event)"
        />
      </v-col>

      <v-col cols="12" md="6">
        <t-password-input
          v-model="confirmPassword"
          label="Confirme sua senha "
          :show="showPassword"
          :rules="confirmPasswordRules"
          hide-details="auto"
          @click:append="showPassword = !showPassword"
        />
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-col cols="12" class="pa-0">
          <t-btn
            color="grey"
            block
            class="heading--text"
            large
            @click="$emit('cancel')"
          >
            Voltar
          </t-btn>
        </v-col>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-col cols="12" class="pa-0">
          <t-btn
            color="success"
            block
            type="submit"
            class="heading--text"
            large
            :disabled="!valid"
          >
            Avançar
          </t-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import TDateInput from "@/components/core/TDateInput.vue";
import TEmailInput from "@/components/core/TEmailInput.vue";
import TPasswordInput from "@/components/core/TPasswordInput.vue";
import TPhoneInput from "@/components/core/TPhoneInput.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TSelect from "@/components/core/TSelect.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import userExists from "@/api/requests/userExists";
import getAvaibleGenders from "@/api/requests/getAvaibleGenders";
import GenderData from "@/types/GenderData";

export default Vue.extend({
  name: "FormUserBasicData",

  components: {
    TBtn,
    TDateInput,
    TEmailInput,
    TPasswordInput,
    TPhoneInput,
    TTextInput,
    TCpfInput,
    TSelect,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },

  data: () => ({
    emailErrorBag: [] as string[],
    confirmEmail: "",
    nameRules: [] as ((v?: string) => boolean | string)[],
    confirmEmailRules: [] as ((v?: string) => boolean | string)[],
    confirmPassword: "",
    confirmPasswordRules: [] as ((v?: string) => boolean | string)[],
    items: ["Brasileiro", "Argentino", "Alemão"],
    showPassword: false,
    valid: true,
    genders: [] as GenderData[],
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  mounted() {
    this.setNameRules();
    this.setConfirmEmailRules();
    this.setConfirmPasswordRules();
    this.getGenders();
  },

  methods: {
    async getGenders() {
      const genders = await getAvaibleGenders();
      this.genders = genders.data;
    },
    setNameRules() {
      this.nameRules = [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (v?: string): boolean | string =>
          !this.user.name || this.user.name.length > 2 || "O nome precisa ter mais que 2 letras.",
      ] as ((v?: string) => boolean | string)[];
    },
    setConfirmEmailRules() {
      this.confirmEmailRules = [
        (v?: string): boolean | string =>
          !this.user.email || v === this.user.email || "Os e-mails informados são diferentes",
      ] as ((v?: string) => boolean | string)[];
    },
    setConfirmPasswordRules() {
      this.confirmPasswordRules = [
        (v?: string): boolean | string =>
          !this.user.password || v === this.user.password || "As senhas informadas são diferentes",
      ] as ((v?: string) => boolean | string)[];
    },
    async checkEmail() {
      const result = await userExists("email", this.user.email);

      if (result) {
        this.emailErrorBag = [ "Esse e-mail já está em uso" ];
      }
    },
    onEmailInput($event: string) {
      this.emailErrorBag = [];
      this.update("email", $event);
    },
    submit() {
      if (!this.form.validate()) return;

      this.$emit("submit");
    },
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
  },
});
</script>
