<template>
  <div>
    <t-section-header :header="header" :icon="icon">
      <template #input>
        <slot name="input" />
      </template>
    </t-section-header>

    <slot />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import TSectionHeader from "@/components/core/TSectionHeader.vue";

export default Vue.extend({
  name: "TSection",

  components: {
    TSectionHeader,
  },

  props: {
    header: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
});
</script>
