import { MiddlewareContext } from ".";

/**
 * Searches for a slug in route params to change company theme
 *
 * @param context `MiddlewareContext`
 * @returns `void`
 */
export function company(context: MiddlewareContext) {
  const isProducerRoute = context.to.path.includes("/producers/");

  if (context.to.params?.company !== undefined && !isProducerRoute) {
    context.store.dispatch("producers/setBySlug", context.to.params.company);
  }

  return context.next();
}
