import { AnalyticsStrategy } from "@/contracts/AnalyticsStrategy";
import { TrackableEvent } from "./TrackableEvents";

export class MetaPixelAnalyticsStrategy implements AnalyticsStrategy {
  identifier: string;
  configured = false;

  private events: Record<TrackableEvent, string> = {
    [TrackableEvent.AppDownload]: "AppDownload",
    [TrackableEvent.PageView]: "PageView",
    [TrackableEvent.AppView]: "AppView",
    [TrackableEvent.Login]: "Login",
    [TrackableEvent.Register]: "CompleteRegistration",
    [TrackableEvent.AddToCart]: "AddToCart",
    [TrackableEvent.RemoveFromCart]: "RemoveFromCart",
    [TrackableEvent.AddPaymentInfo]: "AddPaymentInfo",
    [TrackableEvent.AbandonCart]: "AbandonCart",
    [TrackableEvent.Purchase]: "Purchase",
  };

  private standardEvents = [
    "AddPaymentInfo",
    "AddToCart",
    "AddToWishlist",
    "CompleteRegistration",
    "Contact",
    "CustomizeProduct",
    "Donate",
    "FindLocation",
    "InitiateCheckout",
    "Lead",
    "Purchase",
    "Schedule",
    "Search",
    "StartTrial",
    "SubmitApplication",
    "Subscribe",
    "ViewContent",
  ];

  constructor(identifier: string) {
    this.identifier = identifier;
  }

  init() {
    if (this.configured) return;

    this.configured = true;
    window.fbq("init", this.identifier);
  }

  send(event: TrackableEvent, params: Record<string, unknown>) {
    if (!this.configured) this.init();

    const mapped = this.events[event];

    console.log(`[Analytics] - Event ${mapped} sent to ${this.identifier}`);

    if (this.standardEvents.includes(mapped)) {
      return window.fbq("trackSingle", this.identifier, mapped, params);
    }

    return window.fbq("trackSingleCustom", this.identifier, mapped, params);
  }
}

export class GoogleAnalyticsStrategy implements AnalyticsStrategy {
  identifier: string;
  configured = false;

  private events: Record<TrackableEvent, string> = {
    [TrackableEvent.AppDownload]: "app_download",
    [TrackableEvent.PageView]: "page_view",
    [TrackableEvent.AppView]: "app_view",
    [TrackableEvent.Login]: "login",
    [TrackableEvent.Register]: "sign_up",
    [TrackableEvent.AddToCart]: "add_to_cart",
    [TrackableEvent.RemoveFromCart]: "remove_from_cart",
    [TrackableEvent.AddPaymentInfo]: "add_payment_info",
    [TrackableEvent.AbandonCart]: "abandon_cart",
    [TrackableEvent.Purchase]: "purchase",
  };

  constructor(identifier: string) {
    this.identifier = identifier;
  }

  init() {
    if (this.configured) return;

    this.configured = true;
    window.gtag("config", this.identifier);
  }

  send(event: TrackableEvent, params: Record<string, unknown>) {
    if (!this.configured) this.init();

    const mapped = this.events[event];

    params = {
      ...params,
      send_to: this.identifier,
    };

    console.log(`[Analytics] - Event ${mapped} sent to ${this.identifier}`);

    return window.gtag("event", mapped, params);
  }
}
