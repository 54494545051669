<template>
  <v-dialog
    v-model="value"
    :fullscreen="isMobile"
    width="600"
    :persistent="dialog.config.persistent"
    @click:outside="closeIfClosable"
  >
    <router-view name="dialog" />
    <slot />
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { State as DialogState } from "@/store/modules/dialog";
import Login from "@/views/UserLogin.vue";
import AcessoSocio from "@/views/AcessoSocio.vue";
import LoginSocio from "@/views/LoginSocio.vue";
import Register from "@/views/User/CreateUser.vue";
import Recover from "@/views/User/Recover.vue";
import EventDescription from "@/components/core/Events/TEventDescription.vue";
import EventMap from "@/components/core/Events/TEventMap.vue";

const routes: RouteConfig[] = [
  {
    path: "/login",
    name: "Login",
    components: {
      dialog: Login,
    },
  },
  {
    path: "/acesso/socio",
    name: "Acesso",
    components: {
      dialog: AcessoSocio,
    },
  },
  {
    path: "/login/socio",
    name: "LoginSocio",
    components: {
      dialog: LoginSocio,
    },
  },
  {
    path: "/register",
    name: "Register",
    components: {
      dialog: Register,
    },
  },
  {
    path: "/recover",
    name: "PasswordRecover",
    components: {
      dialog: Recover,
    },
  },
  {
    path: "/event/description",
    name: "EventDescription",
    components: {
      dialog: EventDescription,
    },
  },
  {
    path: "/event/map",
    name: "EventMap",
    components: {
      dialog: EventMap,
    },
  },
];

const router = new VueRouter({
  mode: "abstract",
  base: import.meta.env.BASE_URL,
  routes,
});

export default Vue.extend({
  router: router,
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    route(): string {
      return this.dialog.route;
    },
    value(): boolean {
      return this.dialog.visible;
    },
    dialog(): DialogState {
      return this.$store.state.dialog;
    },
    visible: {
      get(): boolean {
        return this.dialog.visible;
      },
      set(): void {
        this.$store.commit("dialog/hide");
      },
    },
  },
  watch: {
    route(): void {
      router.push(this.dialog.route);
    },
  },
  beforeMount() {
    this.$store.commit("dialog/hide");
    router.push("/");
  },
  methods: {
    closeIfClosable() {
      if (this.dialog.config.persistent) return;

      this.$store.commit("dialog/hide");
    },
  },
});
</script>
